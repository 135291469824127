import React, { useState } from 'react';
import _ from 'underscore';
import LoaderButton from '../../components/LoaderButton';
import { Dialog } from '@reach/dialog';
import ReactTable from 'react-table';
import { ChildProps } from '../../types';
import { Text, TextOnly } from '../../components/Text';
import { toast } from 'react-toastify';
import ChangePayment from './ChangePayment';
import { SHOP_STATES, PAYMENT_STATES } from '../../CONSTANTS';
import NextPayment from './NextPayment';
import SubEndingNotify from './SubEndingNotify';
import AlertModal from '../../components/AlertModal';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { useScreenSize } from '../../context/ScreenSize';

import {
  scalePdf,
  buildReceiptPdf,
  formatDateTime2Lines,
} from '../../libs/utils';

import {
  rechargeDisputedPayment,
} from '../../libs/db-lib';

interface ManageBillingProps extends ChildProps {
  oemRegions: object;
}
const ManageBilling = (props: ManageBillingProps) => {

  const {
    user,
    currentShop,
    config,
    fetchUser,
    oemRegions,
  } = props;

  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPaymentHistoryModal, setShowPaymentHistoryModal] = useState(false);
  const [isLoadingRepayDisputedPayment, setIsLoadingRepayDisputedPayment] = useState(false);
  const [showRepayDisputedPaymentModal, setShowRepayDisputedPaymentModal] = useState(false);
  const [isLoadingChargeRepayment, setIsLoadingChargeRepayment] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [currentReceipt, setCurrentReceipt] = useState<any>(null);
  const [pdfScale, setPdfScale] = useState(scalePdf(window.outerWidth));

  const { windowWidth, isMobile } = useScreenSize();

  const collapseColumns = windowWidth < 574 || isMobile;

  const amountColWidth = windowWidth > 700 ? 100 : 90;
  const statusColWidth = windowWidth > 700 ? 100 : 80;

  const compareDates = (a: any, b: any, desc: any) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (desc) {
      if (dateA > dateB) {
        return 1;
      } else  {
        return -1;
      }
    } else {
      if (dateA < dateB) {
        return -1;
      } else {
        return 1;
      }
    }
  };

  const transactionColDefs = [];
  transactionColDefs.push(
    {
      Header: <Text tid="date" />,
      accessor: 'fullDate',
      minWidth: 120,
      maxWidth: 120,
      sortMethod: compareDates,
      Cell: (row: any) => {
        const dateObj = formatDateTime2Lines(row.original.fullDate);
        return (
          <div>
            {dateObj.date}
            <br />
            {dateObj.time}
          </div>
        );
      },
    },
    {
      Header: <Text tid="amount" />,
      id: 'amount',
      accessor: 'amount',
      minWidth: 90,
      maxWidth: amountColWidth,
      Cell: (row: any) => {
        let amount = parseFloat(row.original.amount);
        if (amount < 0) {
          return `-$${(-amount).toFixed(2)}`;
        }
        return `$${row.original.amount}`;
      },
    },
    {
      Header: <Text tid="chargeDescription" />,
      id: 'chargeDescription',
      accessor: 'type',
      minWidth: 100,
    },
    {
      Header: (
        <div className="u-text-center">
          <Text tid="status" />
        </div>
      ),
      id: 'status',
      accessor: 'status',
      minWidth: 85,
      maxWidth: statusColWidth,
      Cell: (row: any) => {
        let status = row.original.status;
        let failedCharge = status === TextOnly('failedCharge');

        return (
          <div
            className={`c-tag-${
              failedCharge ? 'error' : 'success'
            } u-margin-none u-margin-right`}
          >
            <span className={`c-tag__item`}>
              <span
                className={`c-btn__icon fal fa-${
                  failedCharge ? 'times' : 'check'
                }-circle`}
              />
              {windowWidth < 1348 && windowWidth >= 500 ? <br /> : null}
              {windowWidth > 500 ? status : null}
            </span>
          </div>
        );
      },
    },
    {
      Header: <Text tid="receipt" />,
      id: 'receipt',
      minWidth: 80,
      maxWidth: 80,
      Cell: (row: any) => {
        return (
          <div style={{ justifyContent: 'center' }}>
            <button
              className="c-btn-icon-outline u-margin-right"
              onClick={() => handleViewReceipt(row.original.id)}
            >
              <div className="c-btn__inner">
                <span
                  className="c-btn__icon fas fa-receipt"
                  title={TextOnly('viewReceipt')}
                />
              </div>
            </button>
          </div>
        );
      },
    }
  );

  const handlePaymentHistoryButton = (event: any) => {
    event.preventDefault();
    setShowPaymentHistoryModal(true);
  }
  const handleRepayDisputedPaymentButton = () => {
    if (currentShop?.shopPaymentInfo?.last4) {
      setIsLoadingRepayDisputedPayment(true);
      setShowRepayDisputedPaymentModal(true);
    } else {
      setAlertTitle(TextOnly('error'));
      setAlertMessage(TextOnly('noPaymentMethodToRepayDisputedPayment'));
      setShowModal(true);
    }
  };

  const handleChargeRepayment = async () => {
    setIsLoadingChargeRepayment(true);

    let result = await rechargeDisputedPayment(currentShop.shopID);

    if (result?.error) {
      setAlertTitle(TextOnly('error'));
      setAlertMessage(result.error);
      setShowModal(true);
      setShowRepayDisputedPaymentModal(false);
      setIsLoadingRepayDisputedPayment(false);
      setIsLoadingChargeRepayment(false);
    } else {
      toast.dismiss();
      let toastMessage = TextOnly('shopReinstated');
      toastMessage += ' ' + TextOnly('youHaveBeenChargedAmount', {amount: Number(result.payment).toFixed(2)});
      toast.success(toastMessage, {
        containerId: 'standard',
      });

      setShowRepayDisputedPaymentModal(false);
      setIsLoadingRepayDisputedPayment(false);
      setIsLoadingRepayDisputedPayment(false);
      setIsLoadingChargeRepayment(false);

      await fetchUser(currentShop.shopID);
    }
  }

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
      setShowReceiptModal(false);
      setShowPaymentHistoryModal(false);

    }, 350);
  };

  const handleCancelReceiptModal = () => {
    setShowModal(false);
    setShowReceiptModal(false);
  };

  const handleViewReceipt = (transactionId: string) => {
    let transaction: any = currentShop?.shopPaymentInfo?.transactions.find(
      (trans: any) => {
        return trans.id === transactionId;
      }
    );
    let description = '';
    if (transaction?.type === 'upgradeProration') {
      description = TextOnly('userCapacityUpgrade');
    } else if (transaction.type === 'upgradePlusProration') {
      description = TextOnly('plusUpgrade');
    } else if (transaction.type === 'subscriptionRenew') {
      description = `AutoAuth ${TextOnly('yearlySubscriptionRenewal')}`;
    } else if (transaction.type === 'initialSubscriptionCharge') {
      description = `AutoAuth ${TextOnly('initialSubscriptionCharge')}`;
    } else if (transaction.type === 'paymentRefund') {
      description = TextOnly('paymentRefund');
    } else if (transaction.type === 'upgradeOEMRegionProration') {
      description = TextOnly('upgradeOEMRegionProration');
    } else if (transaction.type === 'downgradeOEMRegionProrationCredit') {
      description = TextOnly('downgradeOEMRegionProrationCredit');
    } else if (transaction.type === 'disputedPaymentRepay') {
      description = TextOnly('disputedPaymentRepay');
    } else if (transaction.type === 'upgradeTechCertProration') {
      description = TextOnly('upgradeTechCertProration');
    } else if (transaction.type === 'crmInitialSubcriptionCharge') {
      description = TextOnly('crmInitialSubcriptionCharge');
    } else if (transaction.type === 'crmSubscriptionRenew') {
      description = TextOnly('crmSubscriptionRenewal');
    } else {
      description = transaction.type;
    }
    transaction.description = description;

    let receiptDoc = buildReceiptPdf(
      transaction,
      currentShop,
      user,
      oemRegions,
    );
    setCurrentReceipt(receiptDoc);
    setShowReceiptModal(true);
  };

  const handlePrintReceipt = (e: any) => {
    e.preventDefault();
    let doc = currentReceipt?.doc;
    window.open(doc.output('bloburl'), '_blank');
  };


  const { shopPaymentInfo } = currentShop;
  const lastFourStr = shopPaymentInfo?.last4
    ? ' ...' + shopPaymentInfo?.last4
    : undefined;


  const shopState = currentShop?.shopState || '';
  const suspendReason = currentShop?.suspendReason || '';
  const paidThruDate = shopPaymentInfo
    ? new Date(shopPaymentInfo.paidThroughDate).getTime()
    : 0;
  const today = new Date().getTime();
  const expired = paidThruDate - today < 0;


  let disputeAmount: Number | null = currentShop?.disputeAmount ?
        Number(parseFloat(currentShop?.disputeAmount).toFixed(2))
        : null;
  if (!disputeAmount) {
    const disputedTrans: any = currentShop?.shopPaymentInfo?.transactions.find((trans: any, index) => {
      return trans.type !== 'paymentRefund';
    });
    disputeAmount = Number(parseFloat(disputedTrans?.amount).toFixed(2));

  }

  let transactions = !currentShop?.shopPaymentInfo?.transactions
  ? []
  : currentShop.shopPaymentInfo.transactions.map((tran: any, index) => {
      let dateStr =
        tran.time.indexOf('T') > 0
          ? tran.time.substring(0, tran.time.indexOf('T'))
          : tran.time;
      let typeStr = '';
      let refundAmount;
      if (tran.type === 'upgradeProration') {
        typeStr = TextOnly('userCapacityUpgrade');
      } else if (tran.type === 'upgradePlusProration') {
        typeStr = TextOnly('plusUpgrade');
      } else if (tran.type === 'subscriptionRenew') {
        typeStr = `AutoAuth ${TextOnly('yearlySubscriptionRenewal')}`;
      } else if (tran.type === 'initialSubscriptionCharge') {
        typeStr = `AutoAuth ${TextOnly('initialSubscriptionCharge')}`;
      } else if (tran.type === 'paymentRefund') {
        typeStr = TextOnly('paymentRefund');
        refundAmount = tran.amount[0] === '-'
          ? tran.amount
          : `-${tran.amount}`;
      } else if (tran.type === 'upgradeOEMRegionProration') {
        typeStr = TextOnly('upgradeOEMRegionProration');
      } else if (tran.type === 'downgradeOEMRegionProrationCredit') {
        typeStr = TextOnly('downgradeOEMRegionProrationCredit');
      } else if (tran.type === 'disputedPaymentRepay') {
        typeStr = TextOnly('disputedPaymentRepay');
      } else if (tran.type === 'upgradeTechCertProration') {
        typeStr = TextOnly('upgradeTechCertProration');
      } else if (tran.type === 'crmInitialSubcriptionCharge') {
        typeStr = TextOnly('crmInitialSubcriptionCharge');
      } else if (tran.type === 'crmSubscriptionRenew') {
        typeStr = TextOnly('crmSubscriptionRenewal');
      } else {
        typeStr = tran.type;
      }

      let failedCharge =
        [
          'gateway_rejected',
          'processor_declined',
          'settlement_declined',
        ].indexOf(tran.status) !== -1;

      return {
        id: tran.id,
        date: dateStr,
        fullDate: tran.time,
        amount: tran.type === 'paymentRefund'
          ? refundAmount
          : tran.amount,
        type: typeStr,
        status: failedCharge
          ? TextOnly('failedCharge')
          : TextOnly('success'),
      };
    });

  return (
    <>
      <div className="u-height-min-800 l-container-sm l-container-flex-box">
        <div>
        { lastFourStr ?
          <div>
            <div>
              <label className={"text-bold"}>
                <Text tid="savedPaymentInfo" />:
              </label>
              <div>
                <label>
                  {shopPaymentInfo?.cardType} {lastFourStr}
                </label>
                <label>
                  <Text tid="paidThru" />{' '}
                  {shopPaymentInfo?.paidThroughDate}
                </label>
                <label>
                  <Text tid="exp" />:{' '}
                  {shopPaymentInfo?.expirationDate}
                </label>
              </div>
            </div>
          </div>
        : '' }
        </div>
        <>
          <ChangePayment
            config={config}
            currentShop={currentShop}
            fetchUser={fetchUser}
          />
          { currentShop!.shopSubscriptionState === PAYMENT_STATES.USER_CANCEL ?
            ''
            :
            <NextPayment
              currentShop={currentShop}
              user={user}
              subscriptionType={'AutoAuth'}
              config={config}
            />
          }
          { currentShop.crmState === 'ACTIVE' ?
            currentShop!.crmSubscriptionState === PAYMENT_STATES.USER_CANCEL ?
              ''
              :
              <NextPayment
                currentShop={currentShop}
                user={user}
                subscriptionType={'CRM'}
                config={config}
              />
          : '' }
          <div className="c-box--white">
            <div className="u-padding-top-large">
              <button
                className="c-btn-outline c-btn-full"
                onClick={handlePaymentHistoryButton}
              >
                <Text tid="viewPaymentHistory" />
              </button>
            </div>
            <div className='l-container-flex-box'>
              { (shopState === SHOP_STATES.TERMINATED ||
                  shopState === SHOP_STATES.SUSPENDED) && suspendReason === 'DISPUTE_LOST' ?
                <LoaderButton
                  id="repayDisputedPayment"
                  type="button"
                  className="red-btn"
                  isLoading={isLoadingRepayDisputedPayment}
                  text={
                    <>
                      { TextOnly('repayDisputedPayment') }
                      <br />
                      (${disputeAmount} + $15.00)
                    </> }
                    onClick={handleRepayDisputedPaymentButton.bind(this)}
                    loadingText={TextOnly('repayingPayment')}
                />
              : '' }
            </div>
          </div>
        </>
      </div>
      <Dialog
        isOpen={showRepayDisputedPaymentModal}
        onDismiss={handleCancelModal}
        className="c-modal--medium"
        aria-label={TextOnly('confirmRepaymentDisputedPayment')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="confirmRepaymentDisputedPayment" />
        </h1>

        <div className="c-modal__body">
          <p>
            <Text tid="consentToRepayPlusFifteen" />
          </p>
          <p>
            <Text tid={"doYouConsent"} sub={{amount: <>{disputeAmount}</>, chargeAmount: <>{Number(Number(disputeAmount)+15).toFixed(2)}</>}}/>
          </p>
          <p>
            <button
              className="c-btn-outline"
              onClick={handleCancelModal}
              disabled={isLoadingChargeRepayment}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={isLoadingChargeRepayment}
              text={TextOnly('confirm')}
              loadingText={TextOnly('chargingRepayment')}
              onClick={handleChargeRepayment}
            />
          </p>
        </div>
      </Dialog>

      <Dialog
        isOpen={showReceiptModal}
        onDismiss={handleCancelReceiptModal}
        className="c-modal"
        aria-label={TextOnly('autoAuthReceipt')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancelReceiptModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>

        <div className="c-modal__body">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '9rem',
            }}
          >
            <Document
              file={currentReceipt?.data || null}
              loading={TextOnly('loading')}
            >
              <Page pageNumber={1} scale={pdfScale} />
            </Document>
          </div>
          <div>
            <button
              className="c-btn"
              onClick={handlePrintReceipt}
            >
              <Text tid="print" />
            </button>{' '}
            <button
              className="c-btn-outline"
              onClick={handleCancelReceiptModal}
            >
              <Text tid="dismiss" />
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={showPaymentHistoryModal}
        onDismiss={handleCancelModal}
        className="c-modal-slider"
        aria-label={TextOnly('paymentHistory')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="paymentHistory" />
        </h1>
        <div className={windowWidth > 700 ? "c-modal__body" : "c-modal__body-xs"}>
          <ReactTable
            columns={transactionColDefs}
            data={transactions}
            className="dropdown -highlight"
            showPaginationTop={true}
            previousText={TextOnly('previousPage')}
            nextText={TextOnly('nextPage')}
            pageText={TextOnly('page')}
            ofText={TextOnly('of')}
            rowsText={TextOnly('rows')}
            noDataText={TextOnly('noDataYet')}
            defaultPageSize={10}
            defaultSorted={[
              {
                id: 'fullDate',
                desc: true,
              },
            ]}
            getTdProps={(state: any, rowInfo: any, column: any) => {
              state;
              rowInfo;
              return {
                style: {
                  justifyContent:
                    column.id === 'status' ?
                    'center' :
                    column.id === 'amount' ? 'right' : 'left',
                  display:
                    (column.id === 'chargeDescription'  || column.id === 'receipt') && collapseColumns
                      ? 'none'
                      : 'flex',
                  paddingRight: (column.id === 'amount' && windowWidth > 574) ? 20 : 10,
                },
              };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              return {
                style: {
                  display:
                    (column?.id === 'chargeDescription' || column?.id === 'receipt') &&
                    collapseColumns
                      ? 'none'
                      : 'block',
                },
              };
            }}
            SubComponent={
              windowWidth < 574 || isMobile
                ? (row) => {
                    return (
                      <div>
                        <div>
                          <span className="text-bold">
                            <Text tid="chargeDescription" />:
                          </span><br />
                          {row.original.type}
                        </div>
                        <div
                          className={`c-tag-${
                            row.original.failedCharge ? 'error' : 'success'
                          } x-small u-margin-none u-margin-right`}
                        >
                          <span className={`c-tag__item`} >
                            <span
                              className={`c-btn__icon fal fa-${
                                row.original.failedCharge ? 'times' : 'check'
                              }-circle`}
                            />
                            {row.original.status}
                          </span>
                        </div>
                        <div>
                          <div style={{ justifyContent: 'center' }}>
                            <button
                              className="c-btn-icon-outline u-margin-right"
                              onClick={() => handleViewReceipt(row.original.id)}
                            >
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fas fa-receipt"
                                  title={TextOnly('viewReceipt')}
                                />
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  }
                : undefined
            }
          />
        </div>
      </Dialog>
      <AlertModal
        title={alertTitle}
        message={alertMessage}
        showModal={showModal}
        size="small"
        handleCancel={handleCancelModal}
      />
    </>
  )

}

export default ManageBilling;