import React, { useState, useEffect } from 'react';
import { Header } from '../../../components/Header';
import { TextOnly } from '../../../components/Text';
import { Loading } from '../../../components/Loading';
import { toast } from 'react-toastify';
import { crmGetShopCustomers } from '../../../libs/db-lib';
import CustomerTable from './CustomerTable';


const ManageCustomers: React.FC<any> = (props) => {
  const { currentShop, user } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allCustomers, setAllCustomers] = useState<any[]>([]);

  const getAllCustomers = async () => {
    setIsLoading(true);
    const response: any = await crmGetShopCustomers(currentShop.shopID);
    if (!response?.error) {
      response.forEach((customer: any) => {
        customer.filterValue = Object.values(customer)
          .join(' ')
          .toLocaleLowerCase();
      });
      setAllCustomers(response);
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
    setIsLoading(false);
  };

  const updateSingleCustomer = (updatedCustomer: any, newVehicle?: any, action?: string) => {
    let newCustomer = updatedCustomer;

    //Updating customer vehicle
    if (newVehicle && action) {
      let newCustomerVehicles;
      const customer = updatedCustomer
      if (action === 'ADD') {
        newCustomerVehicles = customer.vehicles;
        newCustomerVehicles.push(newVehicle);
      } else if (action === 'UPDATE') {
        newCustomerVehicles = customer.vehicles.map((vehicle: any) => {
          if (vehicle.vehicleID !== newVehicle.vehicleID) {
            return vehicle;
          } else {
            return newVehicle;
          }
        });
      } else /* if (action === 'REMOVE') */ {
        newCustomerVehicles = customer.vehicles.filter((vehicle: any) =>
          vehicle.vehicleID !== newVehicle.vehicleID
        );
      }
      customer.vehicles = newCustomerVehicles;
      newCustomer = customer;
    }

    //Updating customer information
    const updatedCustomers = allCustomers.map( customer =>{
      if (customer.customerID === newCustomer.customerID) {
        return {...customer, ...newCustomer}
      } else {
        return customer
      }
    })
    setAllCustomers(updatedCustomers);
  };

  const addNewCustomer = (customer: any) => {
    customer.filterValue = Object.values(customer)
      .join(' ')
      .toLocaleLowerCase();
    setAllCustomers([customer, ...allCustomers])
  }

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    getAllCustomers();
  }, []);

  return (
    <div className="u-padding-bottom">
      <Header title={TextOnly('manageCustomers')} />
      {isLoading ? (
        <Loading />
      ) : (
        <CustomerTable
          addNewCustomer={addNewCustomer}
          allCustomers={allCustomers}
          currentShop={currentShop}
          user={user}
          history={props.history}
          onChange={updateSingleCustomer}
        />
      )}
    </div>
  );
};

export default ManageCustomers;
