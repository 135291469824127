import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import { convertOrderNumToDisplay, getAppPath } from '../../../libs/utils';
import { TechAvatar } from '../../../components/TechAvatar';
import { Dialog } from '@reach/dialog';
import AddEditOrderForm from './AddEditOrderForm';
import { ORDER_STATUS_OPTIONS } from '../CrmConstants';
import { Draggable } from './Draggable';
import { useScreenSize } from '../../../context/ScreenSize';
import { Loading } from '../../../components/Loading';

interface KanbanCardProps {
  data: any;
  currentShop: any;
  onChange: any;
  shopCustomers: any[];
  isSingleCustomer?: boolean;
  user: any;
  isAccordion?: boolean;
  isEmbedded?: boolean;
  setIsOrderModalOpen?: Function;
}

const KanbanCard: React.FC<KanbanCardProps> = ({
  data,
  currentShop,
  onChange,
  shopCustomers,
  isSingleCustomer,
  user,
  isAccordion,
  isEmbedded,
 }) => {
  const { windowWidth } = useScreenSize();
  const isOnKanban = getAppPath() === '/crm/manageOrders';

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingOrder, setEditingOrder] =useState<any>();

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.kanban-edit-order')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);
  };

  const statusColorIndex: number = ORDER_STATUS_OPTIONS.findIndex((status: any) => status.value === data?.status);

  return (
    <>
      <Draggable id={data.orderID} >
        <div
          className={`c-box--white kanban-card ${isEmbedded && 'box-shadow-none u-width-max-content no-bottom-margin'} ${!isOnKanban && 'undraggable'}`}
          onClick={() => {
            if (isAccordion) {
              setEditingOrder(data);
              setShowModal(true);
            }
          }}
        >
          <div className="flex-row flex-space-between align-items-center">
            <div
              className={`${isEmbedded ? 'u-font-weight-bold' : 'text-link'} white-space-nowrap"`}
              onClick={() => {
                if (!isAccordion && !isEmbedded) {
                  setEditingOrder(data);
                  setShowModal(true);
                }
              }}
            >
              {windowWidth > 1600 || (windowWidth <= 1100 && windowWidth >= 960) || windowWidth <= 767 ?
                `${TextOnly('order')} #${convertOrderNumToDisplay(data?.orderNumber)}`
                : `#${convertOrderNumToDisplay(data?.orderNumber)}`
              }
            </div>
            { isOnKanban ?
              <i
                className={`
                  u-padding-left
                  fa fa-circle
                  ${windowWidth > 1600 || (windowWidth <= 1100 && windowWidth >= 960) || windowWidth <= 767 ?
                    'u-padding-right-med'
                    : ''
                  }
                  ${windowWidth <= 1200 && !(windowWidth <= 1100 && windowWidth >= 960) && windowWidth > 767 ?
                    'u-font-size-small'
                    : ''
                  }
                  status-color-${statusColorIndex}
                `}
                aria-hidden="true"
              />
              :
              <div className={`u-padding-left u-font-weight-bold u-text-transform-uppercase status-color-${statusColorIndex}`}>{TextOnly(data?.status)}</div>
            }
          </div>
          <div className="h2 c-box__title">{data?.title}</div>
          <div className={"flex-row flex-space-between"}>
            <section>
              <div>
                {(windowWidth > 1600 || (windowWidth <= 1100 && windowWidth >= 960) || windowWidth <= 767) && (
                  <i className="fa fa-car" aria-hidden="true" />
                )}&nbsp;
                <span className={windowWidth > 1600 || (windowWidth <= 1100 && windowWidth >= 960) || windowWidth <= 767 ? "u-padding-left-small" : ''}>
                  {windowWidth > 1600 || windowWidth <= 767 ?
                    (data?.yearMakeModel || `${data.vehicle?.year} ${data.vehicle?.make} ${data.vehicle?.model}`)
                    : `'${data?.yearMakeModel?.substring(2) ||
                    `${data.vehicle?.year} ${data.vehicle?.make} ${data.vehicle?.model}`.substring(2)
                    }`
                  }
                </span>
              </div>
              <div>
                {(windowWidth > 1600 || (windowWidth <= 1100 && windowWidth >= 960) || windowWidth <= 767) ? (
                  <div className='white-space-nowrap'>
                    <i className="fa fa-user" aria-hidden="true" />&nbsp;
                    <span className="u-padding-left-small">
                      {data?.customerName  || `${data?.customer?.firstName} ${data?.customer?.lastName}`}
                    </span>
                  </ div>
                ) : (
                  <span>
                    {data?.customerNameShortened || `${data?.customer?.firstName?.charAt(0)}. ${data?.customer?.lastName}`}
                  </span>
                )}
              </div>
              { !isOnKanban &&
                <p className="u-font-size-small" style={{ textAlign: 'left' }}>
                  {data?.description}
                </p>
              }
              {!isEmbedded && (windowWidth <= 1600 && windowWidth > 767 && !(windowWidth <= 1100 && windowWidth >= 960)) && data?.tech && (
                <div className="l-flex u-padding-top">
                  <TechAvatar tech={currentShop.shopUsers.find((tech: any)=> tech.userID === data.tech.userID)} />
                </div>
              )}
            </section>
            {(isEmbedded || (windowWidth > 1600 || (windowWidth <= 1100 && windowWidth >= 960) || windowWidth <= 767)) && data?.tech && (
              <div className="l-flex u-align-end u-margin-left">
                <TechAvatar tech={currentShop.shopUsers.find((tech: any)=> tech.userID === data.tech.userID)} />
              </div>
            )}
          </div>
        </div>
      </Draggable>
      <Dialog
        isOpen={showModal}
        onDismiss={handleCancelModal}
        className="c-modal-slider kanban-edit-order"
        id="addEditOrder"
        aria-label={TextOnly('orderDetails')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        { shopCustomers.length === 0 ?
          <Loading /> :
          <AddEditOrderForm
            currentShop={currentShop}
            shopCustomers={shopCustomers}
            handleCloseModal={handleCancelModal}
            orderData={editingOrder}
            onSubmit={onChange}
            isSingleCustomer={isSingleCustomer}
            user={user}
          />
        }
      </Dialog>
    </>
  );
};

export default KanbanCard;